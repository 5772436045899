<template>
    <div class="reset_password" :style="`background-image: url(${loginBg})`">
        <div class="reset_password_contaner">
            <div class="title">重置密码</div>
            <el-input size="medium" v-model.trim="telNo" placeholder="请输入账号" maxlength="11" show-word-limit />
            <el-input size="medium" placeholder="请输入验证码" v-model.trim="captcha" maxlength="4" show-word-limit>
                <el-button slot="append" @click="getCaptchaApi" :disabled="timeNum < 60">
                    {{ timeNum >= 60 ? '获取验证码' : `${timeNum}s` }}
                </el-button>
            </el-input>
            <el-button class="reset_btn" type="primary" @click="onResetPass">重置密码</el-button>
            <div class="other">
                <el-link type="primary" @click="toLoginPage">返回登录</el-link>

                <el-popover placement="top" trigger="hover">
                    <img class="guide_img" :src="botPreview" />
                    <div class="empty">未收到验证码，请联系管理人员</div>
                    <el-link slot="reference">企业微信内如何查看验证码</el-link>
                </el-popover>
            </div>
        </div>
    </div>
</template>
<script>
import { getCaptcha, resetPassword } from '@/js/api/staffApi.js'
import _config from '@/js/config.js'

let _timer = 0
export default {
    data() {
        return {
            loginBg: _config.loginBg,
            botPreview: _config.botPreview,

            telNo: '',
            captcha: '',
            timeNum: 60,
        }
    },
    destroyed() {
        clearInterval(_timer)
        _timer = null
    },
    methods: {
        onResetPass() {
            if (this.telNo && this.captcha) {
                resetPassword({ telNo: this.telNo, captcha: this.captcha }).then(() => {
                    this.$message.success('新密码已发送至企业微信')
                    this.toLoginPage()
                })
            } else {
                this.$message.warning('请输入账号/验证码')
            }
        },
        toLoginPage() {
            this.$router.push('/login')
        },
        getCaptchaApi() {
            if (this.telNo) {
                getCaptcha({ telNo: this.telNo }).then(() => {
                    this.$message.success('验证码已发送至企业微信，请查收')
                    _timer = setInterval(() => {
                        this.timeNum -= 1
                        if (this.timeNum === 0) {
                            this.timeNum = 60
                            clearInterval(_timer)
                        }
                    }, 1000)
                })
            } else {
                this.$message.warning('请输入账号(手机号)')
            }
        },
    },
}
</script>
<style lang="less" scoped>
.reset_password {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .reset_password_contaner {
        width: 420px;
        background: #ffffff;
        border-radius: 12px;
        padding: 0 24px 24px;
        box-sizing: border-box;
        box-shadow: rgba(100, 100, 111, 1) 0px 7px 29px 0px;
    }
}
.title {
    padding: 24px 0;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 12px;
}
/deep/ .el-input {
    margin-bottom: 24px;
    .el-button {
        min-width: 100px;
    }
}
.reset_btn {
    width: 90%;
    height: 48px;
    font-size: 16px;
    letter-spacing: 1px;
    margin: 24px auto 12px;
    display: block;
}

.other {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
}
.guide_img {
    width: 280px;
}
.empty {
    text-align: center;
    color: #000;
    font-size: 12px;
}
</style>
